<template>
  <div>
    <Row :gutter="6" class="m-b-10">
      <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
        <i-select size="small" v-model="assetId" @on-change="handleChangeAsset">
          <Option :value="-1">全部资产</Option>
          <i-option :value="item.id" v-for="item in assetArray" :key="'asset_' + item.id">{{ item.name }}</i-option>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="2" class="m-b-5">
        <i-select size="small" placeholder="资产包" clearable v-model="query.assetPackage">
          <i-option v-for="item in assetPackageArray" :key="'assetPk_' + item.id" :value="item.id">{{ item.name
            }}</i-option>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
        <i-select size="small" placeholder="站点（可通过关键字搜索）" filterable clearable v-model="query.stationId">
          <i-option :value="item.id" v-for="item in stationArray" :key="'station_' + item.id">{{ assetId === -1 ?
            `${item.name}
            - ${item.assetName}` : item.name }}</i-option>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
        <i-select size="small" placeholder="设备大类" v-model="query.deviceBigmodel" @on-change="handleChangeBigmodel">
          <Option v-for="item in deviceTypeArray" :value="item.id" :key="'type' + item.id">{{ item.name }}</Option>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
        <i-select size="small" placeholder="设备小类（可通过关键字搜索）" filterable clearable v-model="query.devicemodel">
          <Option v-for="item in deviceSmallTypeArray" :value="item.id" :key="'stype' + item.id">{{ item.name }}
          </Option>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="2" class="m-b-5">
        <i-select size="small" placeholder="状态" clearable v-model="query.enabled">
          <i-option v-for="item in statusArray" :key="'status_' + item.id" :value="item.id">{{ item.name }}</i-option>
        </i-select>
      </i-col>

      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <i-input size="small" placeholder="关键字：支持名称查询" v-model="query.keyword"></i-input>
      </i-col>
      <i-col span="4" class="m-b-5">
        <i-button size="small" class="m-r-5" type="primary" icon="ios-search" @click="handleSearch()">搜索</i-button>
        <Button size="small" type="primary" class="m-r-5" @click="handleAdd">新增设备</Button>
        <Button size="small" type="success" class="m-r-5" @click="handleImport">设备导入</Button>
        <Button size="small" type="success" @click="handleDownload">设备导出</Button>
      </i-col>
    </Row>

    <Table size="small" stripe :data="list" :columns="tableColumns" :loading="tableLoading"></Table>
    <div class="paging_style">
      <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator :current="query.pageNumber"
        @on-change="handlePageChanged"></Page>
    </div>
    <Modal v-model="deviceAddModal" width="800">
      <p slot="header" class="text-center">{{ operate === 1 ? '新增' : '编辑' }}设备</p>
      <device-add v-if="deviceAddModal" ref="deviceAdd" :operate="operate" :assetPackageArray="assetPackageArray"
        :onSuccess="getPage"></device-add>
      <div slot="footer">
        <Button type="text" class="m-r-5" @click="deviceAddModal = false">取消</Button>
        <Button type="primary" @click="handleAddDevice">确定</Button>
      </div>
    </Modal>
    <device-detail ref="deviceDetail"></device-detail>
    <export-device ref="exportDevice" :assetArray="assetArray" :deviceBigModelArray="deviceTypeArray"></export-device>
    <import-device ref="importDevice" :source="1" :onSuccess="getPage"></import-device>
    <modify-status ref="modifyStatus" :onSuccessFun="getPage"></modify-status>
  </div>
</template>

<script>
import DeviceAdd from './DeviceAdd.vue'
import ExportDevice from '../common/ExportDevice'
import ImportDevice from './ImportDevice'
import DeviceDetail from './DeviceDetail'
import ModifyStatus from './ModifyStatus'
/** new api */
import { getPublisherAssetList, getExistDeviceModelList, selectAssetPackage } from '@/api/rim/asset'
import { getPublisherProductAssetList } from '@/api/product/asset'
import { getRimStationByAssetIds } from '@/api/rim/station'
import { getPublisherDevicePage, getStatusList } from '@/api/rim/device'
export default {
  components: {
    DeviceAdd,
    ExportDevice,
    ImportDevice,
    DeviceDetail,
    ModifyStatus
  },
  data () {
    return {
      assetArray: [], // 实体资产清单
      productAssetArray: [], // 交易资产
      stationArray: [], // 站点清单
      deviceTypeArray: [], // 设备大类型
      deviceSmallTypeArray: [], // 设备小类
      assetPackageArray: [], // 资产包集合
      chooseDeviceId: 0, // 当前选中的资源ID
      statusArray: [],
      operate: 1, // 1：新增，2：修改
      assetId: undefined,
      tableLoading: false,
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 15,
        keyword: '',
        assetId: null,
        deviceBigmodel: null,
        inScope: true,
        devicemodel: null,
        stationId: null,
        enabled: null,
        fault: null,
        graph: null,
        assetPackage: undefined

      },
      list: [],
      tableColumns: [
        { title: '编号', align: 'center', key: 'code' },
        { title: '站点', align: 'center', key: 'stationName' },
        { title: '类型', align: 'center', key: 'devicemodelName' },
        { title: '楼层', align: 'center', key: 'floor' },
        { title: '状态', align: 'center', key: 'enabledName' },
        { title: '面数', align: 'center', key: 'side' },
        {
          title: '制作尺寸(mm)',
          align: 'center',
          render: (h, params) => {
            return h('span', `${params.row.makewidth} * ${params.row.makeheight}`)
          }
        },
        {
          title: '见光尺寸(mm)',
          align: 'center',
          render: (h, params) => {
            return h('span', `${params.row.finalwidth} * ${params.row.finalheight}`)
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.operate = 2
                    this.$store.commit('set_cur_device_info', params.row)
                    this.deviceAddModal = true
                  }
                }
              }, '编辑信息'),
              h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.$store.commit('set_cur_device_info', params.row)
                    this.$refs.modifyStatus.init()
                  }
                }
              }, '编辑状态'),
              h('a',
                {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.chooseDeviceId = params.row.id
                      this.$store.commit('set_cur_device_info', params.row)
                      this.$refs.deviceDetail.showModal()
                      // this.$store.commit('set_show_tab', '4')
                      // this.$store.commit('set_equipment_detailId', params.row.id)
                      // this.showRecordModal = true
                    }
                  }
                }, '详情')
            ])
          }
        }
      ],
      deviceAddModal: false
    }
  },
  created () {
    this.initAssetArray()
    this.getProudctAsset()
    this.getStatusData()
    this.getAssetPackgeData()
  },
  methods: {
    // 初始化资产选项，并默认选中第一条
    initAssetArray () {
      getPublisherAssetList().then((res) => {
        this.assetArray = res
        this.$store.commit('set_asset_array', this.assetArray)
        this.assetId = this.assetArray[0].id
        this.query.assetId = this.assetId
        this.$store.commit('set_selected_assetId', this.query.assetId)
      }).then(() => {
        this.handleChangeAsset()
      })
    },
    getProudctAsset () {
      this.productAssetArray = []
      getPublisherProductAssetList().then(res => {
        if (res && !res.errcode) {
          this.productAssetArray = res
        }
        this.$store.commit('set_product_asset_array', this.productAssetArray)
      })
    },
    // 资产变更
    handleChangeAsset () {
      this.query.assetId = this.assetId === -1 ? undefined : this.assetId
      // const assetIds = this.assetId === -1 ? this.assetArray.map(x => x.id) : [this.assetId]
      this.$store.commit('set_selected_assetId', this.assetId)
      this.initStationArray()
      this.initDeviceTypeArray()
    },
    // 初始化站点选项1
    initStationArray () {
      this.query.stationId = null
      const assetIds = this.assetId === -1 ? this.assetArray.map(x => x.id) : [this.assetId]
      getRimStationByAssetIds({ assetIds: JSON.stringify(assetIds) }).then((res) => {
        this.stationArray = res
        this.$store.commit('set_station_array', this.stationArray)
      })
    },
    // 初始化设备类型选项
    async initDeviceTypeArray () {
      this.query.devicemodel = null
      this.deviceTypeArray = await getExistDeviceModelList({ assetId: this.query.assetId }) || []
      if (this.deviceTypeArray.length) {
        this.query.deviceBigmodel = this.deviceTypeArray[0].id
        this.handleChangeBigmodel()
        this.handleSearch()
      }
    },
    handleChangeBigmodel () {
      this.deviceSmallTypeArray = this.deviceTypeArray.find(x => x.id === this.query.deviceBigmodel).children || []
    },
    getStatusData () {
      getStatusList({ assetId: this.query.assetId }).then(res => {
        if (res && !res.errcode) {
          this.statusArray = res
          this.$store.commit('set_status_array', this.statusArray)
        }
      })
    },
    /**
     * 获取资产包数据
     */
    getAssetPackgeData () {
      this.assetPackageArray = []
      selectAssetPackage().then(res => {
        if (res && !res.errcode) {
          this.assetPackageArray = res
        }
      })
    },
    // 分页
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.getPage()
    },
    // 搜索
    handleSearch () {
      this.query.pageNumber = 1
      // this.$store.commit('set_cur_category', null)
      // this.$store.dispatch('getDeviceStatusStaData', { assetId: this.query.assetId, devicemodel: this.query.devicemodel, stationId: this.query.stationId })
      this.getPage()
      // this.getSvgData()
    },
    // 获取分页列表
    getPage () {
      this.tableLoading = true
      getPublisherDevicePage(this.query).then((res) => {
        if (res && !res.errcode) {
          // res.list.map((item) => {
          //   item.visible = item.visibleScope === 2
          //   return item
          // })
          this.list = res.list
          this.total = res.totalRow
        } else {
          this.list = []
          this.total = 0
        }
      }).finally(() => { this.tableLoading = false })
    },
    handleAdd () {
      this.operate = 1
      this.deviceAddModal = true
    },
    handleAddDevice () {
      this.$refs.deviceAdd.handleSubmit()
      this.deviceAddModal = false
    },
    handleImport () {
      this.$refs.importDevice.show()
    },
    handleDownload () {
      this.$refs.exportDevice.handleDownload()
    }
  }
}
</script>
