<template>
  <div>
    <Modal v-model="modalShow" width="600" footer-hide>
      <p slot="header" class="text-center">编辑状态</p>
      <Form v-model="statusModel" :label-width="100" class="m-b-10">
        <FormItem label="状态" prop="enabled">
          <Select v-model="statusModel.enabled" :transfer="true" size="small" style="width:300px" placeholder="选择资源状态">
            <Option v-for="item in statusArray" :key="'s_' + item.id" :value="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="备注" prop="desc">
          <Input v-model.trim="statusModel.desc" size="small" type="textarea" :rows="4" placeholder="填写备注"
            style="width:300px" />
        </FormItem>
        <FormItem>
          <Button type="primary" size="small" class="m-l-5" @click="handleSubmitEditStatus">确认更新</Button>
        </FormItem>
      </Form>

    </Modal>
  </div>
</template>

<script>

/** new api */
import { updateStatus } from '@/api/rim/device'
export default {
  props: {
    onSuccessFun: {
      type: Function,
      default: () => { }
    }
  },
  data () {
    return {
      modalShow: false,
      statusModel: {
        enabled: -1,
        deviceId: null,
        desc: ''
      }
    }
  },
  computed: {
    deviceInfo () {
      return this.$store.state.deviceManage.curDeviceInfo
    },
    statusArray () {
      return this.$store.state.deviceManage.statusArray
    }
  },
  methods: {
    init () {
      this.statusModel.deviceId = this.deviceInfo.deviceId
      this.statusModel.enabled = this.deviceInfo.enabled
      this.statusModel.desc = ''
      this.modalShow = true
    },
    handleSubmitEditStatus () {
      if (this.statusModel.enabled === -1) {
        this.$Notice.warning({ desc: '请选择状态' })
        return
      }
      if (this.statusModel.desc === '') {
        this.$Notice.warning({ desc: '请选填写备注' })
        return
      }
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要提交本次操作？',
        onOk: () => {
          updateStatus(this.statusModel).then(res => {
            if (res && res.errcode === 0) {
              this.onSuccessFun()
              this.$Notice.success({ desc: '操作成功' })
            }
          }).finally(() => {
            this.modalShow = false
          })
        }
      })
    }
  }
}
</script>
