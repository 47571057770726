<template>
  <div>
    <Form ref="deviceModel" :model="deviceModel" autocomplete="on" name="oohforce-device-add" id="oohforce-device-add"
      :rules="ruleValidate" label-position="top">
      <Row :gutter="8" class="m-b-10">
        <i-col span="8">
          <FormItem label="站点" prop="stationId">
            <i-select v-model="deviceModel.stationId" filterable size="small" placeholder="请选择所属站点（可通过关键字搜索）">
              <i-option v-for="item in stationArray" :value="item.id" :key="'st_' + item.id">{{ assetId === -1 ?
                `${item.name}
                              - ${item.assetName}` : item.name }}</i-option>
            </i-select>
          </FormItem>
        </i-col>
        <i-col span="8">
          <FormItem label="编号" prop="code">
            <Input v-model="deviceModel.code" size="small" placeholder="请填写编号" />
          </FormItem>
        </i-col>
        <i-col span="8">
          <FormItem label="一级类型" prop="deviceBigmodel">
            <i-select v-model="deviceModel.deviceBigmodel" size="small" placeholder="请选择类型"
              @on-change="handleChangeBigModel">
              <i-option v-for="item in deviceCategoryArray" :value="item.id" :key="'dct_' + item.id">{{ item.name }}
              </i-option>
            </i-select>
          </FormItem>
        </i-col>
      </Row>
      <Row :gutter="8" class="m-b-10">
        <i-col span="8">
          <FormItem label="二级类型" prop="devicemodel">
            <i-select v-model="deviceModel.devicemodel" filterable size="small" placeholder="请选择类型（可通过关键字搜索）">
              <i-option v-for="item in deviceTypeArray" :value="item.id" :key="'dt_' + item.id">{{ item.name }}
              </i-option>
            </i-select>
          </FormItem>
        </i-col>
        <i-col span="8">
          <FormItem label="位置" prop="position">
            <i-select v-model="deviceModel.position" size="small" placeholder="请选择位置">
              <i-option v-for="item in positionArray" :value="item.id" :key="'pos_' + item.id">{{ item.name }}
              </i-option>
            </i-select>
          </FormItem>
        </i-col>
        <i-col span="8">
          <FormItem label="楼层" prop="floor">
            <i-select v-model="deviceModel.floor" size="small" placeholder="请选择楼层">
              <i-option v-for="item in floorArray" :value="item.value" :key="'floor_' + item.value">{{ item.name }}
              </i-option>
            </i-select>
          </FormItem>
        </i-col>
      </Row>
      <Row :gutter="8" class="m-b-10">
        <i-col span="8">
          <FormItem label="资产包（作为运维履约的数据范围查看）" prop="assetPackage">
            <i-select v-model="deviceModel.assetPackage" size="small" placeholder="请选择资产包">
              <i-option v-for="item in assetPackageArray" :key="'assetPackge_' + item.id" :value="item.id">{{ item.name
              }}
              </i-option>
            </i-select>
          </FormItem>
        </i-col>
        <i-col span="8">
          <FormItem label="管理单位" prop="manageUnit">
            <i-select v-model="deviceModel.manageUnit" :clearable="true" size="small" placeholder="请选择管理单位">
              <i-option v-for="item in manageCompanyArray" :key="'manageUnit_' + item.id" :value="item.id">{{ item.name
              }}
              </i-option>
            </i-select>
          </FormItem>
        </i-col>
        <i-col span="8">
          <FormItem label="出口">
            <Input v-model="deviceModel.exits" size="small" placeholder="请填写出口" />
          </FormItem>
        </i-col>
      </Row>
      <Row :gutter="8" class="m-b-10">
        <i-col span="8">
          <FormItem label="上下行">
            <i-select v-model="deviceModel.direction" :clearable="true" size="small" placeholder="请选择上下行">
              <i-option v-for="item in directionArray" :value="item.value" :key="'dire_' + item.value">{{ item.name }}
              </i-option>
            </i-select>
          </FormItem>
        </i-col>
        <i-col span="8">
          <FormItem label="街道">
            <Input v-model="deviceModel.street" size="small" placeholder="请填写街道" />
          </FormItem>
        </i-col>
        <i-col span="8">
          <FormItem label="是否通电">
            <i-select v-model="deviceModel.electric" size="small" placeholder="请选择通电">
              <i-option :value="1">是</i-option>
              <i-option :value="0">否</i-option>
            </i-select>
          </FormItem>
        </i-col>
      </Row>
      <Row :gutter="8" class="m-b-10">
        <i-col span="8">
          <FormItem label="是否滚屏">
            <i-select v-model="deviceModel.isroll" size="small" placeholder="请选择滚屏">
              <i-option :value="1">是</i-option>
              <i-option :value="0">否</i-option>
            </i-select>
          </FormItem>
        </i-col>
        <i-col span="8">
          <FormItem label="面数">
            <InputNumber v-model="deviceModel.side" :min="1" :max="9999" style="width:100%" size="small"
              placeholder="请填写面数" />
          </FormItem>
        </i-col>
        <i-col span="8">
          <FormItem label="设备尺寸，宽x高(mm)">
            <Row :gutter="8">
              <i-col span="11">
                <InputNumber v-model="deviceModel.width" :min="0" style="width:100%" size="small" placeholder="宽" />
              </i-col>
              <i-col span="2" class="text-center">*</i-col>
              <i-col span="11">
                <InputNumber v-model="deviceModel.height" :min="0" style="width:100%" size="small" placeholder="高" />
              </i-col>
            </Row>
          </FormItem>
        </i-col>
      </Row>
      <Row :gutter="8" class="m-b-10">
        <i-col span="8">
          <FormItem label="面积(㎡)">
            <InputNumber v-model="deviceModel.area" :min="0" style="width:100%" size="small" placeholder="请填写面积" />
          </FormItem>
        </i-col>
        <i-col span="8">
          <FormItem label="制作尺寸，宽x高(mm)">
            <Row :gutter="8">
              <i-col span="11">
                <InputNumber v-model="deviceModel.makewidth" :min="0" style="width:100%" size="small" placeholder="宽" />
              </i-col>
              <i-col span="2" class="text-center">*</i-col>
              <i-col span="11">
                <InputNumber v-model="deviceModel.makeheight" :min="0" style="width:100%" size="small" placeholder="高" />
              </i-col>
            </Row>
          </FormItem>
        </i-col>
        <i-col span="8">
          <FormItem label="见光尺寸，宽x高(mm)">
            <Row :gutter="8">
              <i-col span="11">
                <InputNumber v-model="deviceModel.finalwidth" :min="0" style="width:100%" size="small" placeholder="宽" />
              </i-col>
              <i-col span="2" class="text-center">*</i-col>
              <i-col span="11">
                <InputNumber v-model="deviceModel.finalheight" :min="0" style="width:100%" size="small" placeholder="高" />
              </i-col>
            </Row>
          </FormItem>
        </i-col>
      </Row>
      <Row :gutter="8" class="m-b-10">
        <i-col span="8">
          <FormItem label="移固日期">
            <DatePicker v-model="deviceModel.transferTime" type="date" size="small" placeholder="请选择移固日期"
              style="width: 100%"></DatePicker>
          </FormItem>
        </i-col>
        <i-col span="8">
          <FormItem label="信息同步到资源">
            <i-select v-model="deviceModel.syncResource" size="small" placeholder="请选择信息同步到资源">
              <i-option :value="1">是</i-option>
              <i-option :value="0">否</i-option>
            </i-select>
          </FormItem>
        </i-col>
        <!-- <i-col span="12">
          <FormItem label="开启方式">
            <i-select
              v-model="deviceModel.openMode"
              size="small"
              placeholder="请选择开启方式"
            >
              <i-option :value="1">上掀开启式</i-option>
              <i-option :value="2">封闭下开启式</i-option>
            </i-select>
          </FormItem>
        </i-col> -->
        <!-- <i-col span="12">
          <FormItem label="设备供应商">
            <i-select
              v-model="deviceModel.supplierId"
              :clearable="true"
              size="small"
              placeholder="请选择设备供应商"
            >
              <i-option
                v-for="item in deviceSupplierArray"
                :key="'deviceSupplier_'+item.id"
                :value="item.id"
              >{{item.name}}</i-option>
            </i-select>
          </FormItem>
        </i-col> -->

      </Row>
      <Row :gutter="8" class="m-b-10">
        <i-col span="16">
          <FormItem label="状态">
            <RadioGroup v-model="deviceModel.enabled" size="small">
              <Radio v-for="item in statusArray" :disabled="operate === 2" :key="'st_' + item.id" :label="item.id">{{
                item.name }}</Radio>
            </RadioGroup>
          </FormItem>
        </i-col>
      </Row>
    </Form>

  </div>
</template>

<script>
// import { getPositions } from '@/api/product/resource'
// import { addDevice, updateDevice } from '@/api/product/deviceedit'
import { ParseDate } from '@/utils/dateFormat'
/** new api */
import { getDeviceModelList, getPositionList } from '@/api/rim/asset'
import { getCompanyList } from '@/api/os/company'
import { submit } from '@/api/rim/device'
export default {
  props: {
    operate: {
      type: Number,
      default: 1
    },
    assetPackageArray: {
      type: Array,
      required: true,
      default: new Array(0)
    },
    onSuccess: Function
  },
  data () {
    return {
      deviceModel: {
        id: null,
        assetId: null,
        code: '',
        deviceBigmodel: null,
        devicemodel: null,
        direction: null,
        electric: 1,
        enabled: 1,
        exits: '',
        fault: false,
        floor: null,
        isroll: 0,
        omId: null,
        position: null,
        stationId: null,
        // supplierId: null,
        width: 0,
        height: 0,
        transferTime: '',
        street: '',
        openMode: 1,
        area: 0,
        manageUnit: null,
        usingUnit: null,
        finalheight: 0,
        finalwidth: 0,
        makeheight: 0,
        makewidth: 0,
        syncResource: 1,
        side: 1,
        assetPackage: null
      },
      deviceCategoryArray: [], // 一级设备类型
      deviceTypeArray: [], // 二级设备类型
      positionArray: [],
      directionArray: [
        { value: 1, name: '上行' },
        { value: 2, name: '下行' }
      ],
      floorArray: [
        { value: -4, name: '地下四层' },
        { value: -3, name: '地下三层' },
        { value: -2, name: '地下二层' },
        { value: -1, name: '地下一层' },
        { value: 1, name: '地上一层' },
        { value: 2, name: '地上二层' },
        { value: 3, name: '地上三层' },
        { value: 4, name: '地上四层' }
      ],
      manageCompanyArray: [], // 管理公司集合
      deviceSupplierArray: [], // 设备供应商集合
      omArray: [], // 运维单位集合
      ruleValidate: {
        stationId: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        code: [
          { required: true, pattern: '[a-z]|[0-9]', message: ' ', trigger: 'blur' }
        ],
        deviceBigmodel: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        devicemodel: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        position: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        floor: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        manageUnit: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        assetPackage: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    assetId () {
      return this.$store.state.deviceManage.selectedAssetId
    },
    assetArray () {
      return this.$store.state.deviceManage.assetArray
    },
    stationArray () {
      return this.$store.state.deviceManage.stationArray
    },
    // deviceTypeArray () {
    //   return this.$store.state.deviceManage.deviceTypeArray
    // },
    statusArray () {
      return this.$store.state.deviceManage.statusArray
    },
    curDeviceInfo () {
      return this.$store.state.deviceManage.curDeviceInfo
    }
  },
  mounted () {
    this.getDeviceTypeData()
    this.getPositionData()
    this.getCompanyData()
  },
  methods: {
    getPositionData () {
      const assetId = this.operate === 2 ? this.deviceModel.assetId : this.assetId
      const assetIds = assetId === -1 ? this.assetArray.map(x => x.id) : [assetId]
      getPositionList({ assetIds: JSON.stringify(assetIds) }).then(res => {
        if (res && !res.errcode) {
          this.positionArray = res
        } else {
          this.positionArray = []
        }
      })
    },
    getDeviceTypeData () {
      const assetId = this.operate === 2 ? this.deviceModel.assetId : this.assetId
      const assetIds = assetId === -1 ? this.assetArray.map(x => x.id) : [assetId]
      getDeviceModelList({ assetIds: JSON.stringify(assetIds) }).then(res => {
        if (res && !res.errcode) {
          this.deviceCategoryArray = res
          if (this.operate === 2) { // 编辑时
            this.handleChangeBigModel()
          }
        } else {
          this.deviceCategoryArray = []
        }
      })
    },
    handleChangeBigModel () {
      this.deviceTypeArray = this.deviceModel.deviceBigmodel ? this.deviceCategoryArray.find(x => x.id === this.deviceModel.deviceBigmodel).children : []
    },
    getCompanyData () {
      const params = { enabled: true, publisherId: this.$store.getters.token.userInfo.publisherId }
      getCompanyList(params).then(res => {
        if (res && !res.errcode) {
          this.manageCompanyArray = res
        } else {
          this.manageCompanyArray = []
        }
      })
    },
    handleSubmit () {
      this.$refs.deviceModel.validate((valid) => {
        if (valid) {
          this.$Modal.confirm({
            title: '操作提示',
            content: '确认信息无误，并提交？',
            onOk: () => {
              // if (this.operate === 1) {
              this.deviceModel.assetId = this.stationArray.find(x => x.id === this.deviceModel.stationId).assetId
              // }
              this.deviceModel.transferTime = this.deviceModel.transferTime ? ParseDate(this.deviceModel.transferTime) : ''
              submit(JSON.stringify(this.deviceModel)).then(res => {
                if (res && res.errcode === 0) {
                  this.onSuccess()
                  this.$Notice.success({ desc: '操作成功' })
                }
              })
            }
          })
        } else {
          this.$Notice.error({ desc: '请正确填写信息' })
        }
      })
    }
  },
  watch: {
    // assetId: {
    //   deep: true,
    //   immediate: true,
    //   handler (val) {
    //     if (val) {
    //       this.getPositionData()
    //     }
    //   }
    // },
    operate: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val === 2) { // 编辑操作
          Object.keys(this.deviceModel).forEach(key => {
            this.deviceModel[key] = this.curDeviceInfo[key]
          })
          this.deviceModel.isroll = Number(this.curDeviceInfo.isroll)
          this.deviceModel.electric = Number(this.curDeviceInfo.electric)
          this.deviceModel.syncResource = 1
        }
      }
    }
  }
}
</script>
