<template>
  <div style="max-height: 620px; overflow-y: auto; overflow-x: hidden;">
    <Table
      stripe
      size="small"
      :data="historyList"
      :columns="tableColumns"
    ></Table>
  </div>
</template>

<script>
import { getHistoryList } from '@/api/rim/device'
export default {
  data () {
    return {
      historyList: [],
      tableColumns: [
        {
          title: '变更内容',
          align: 'center',
          key: 'content',
          render: (h, params) => {
            const contentJson = params.row.content !== '' ? JSON.parse(params.row.content) : []
            const html = []
            contentJson.forEach(item => {
              html.push(h('p', `变更属性：${item.attrName}，变更前：${item.old}，变更后：${item.new}`))
            })
            return h('div', html)
          }
        },
        {
          title: '变更备注',
          align: 'center',
          key: 'remark'
        },
        {
          title: '更新时间',
          align: 'center',
          key: 'createTime'
        },
        {
          title: '变更人',
          align: 'center',
          key: 'userName'
        }
      ]
    }
  },
  computed: {
    deviceInfo () {
      return this.$store.state.deviceManage.curDeviceInfo
    }
  },
  methods: {
    // 初始化资产选项，并默认选中第一条
    init () {
      getHistoryList({ deviceId: this.deviceInfo.deviceId }).then((res) => {
        this.historyList = res
      })
    }
  }
  // watch: {
  //   deviceId: {
  //     deep: true,
  //     immediate: true,
  //     handler (val) {
  //       this.initHistory()
  //     }
  //   }
  // }
}
</script>
